export const toolsMap: any[] = [
    {
        label: '添加Made In China',
        value: 1,
        path: '/Tool/index.html'
    },
    {
        label: '尾程派送费计算',
        value: 2,
        path: '/Tool/AmzFee.html'
    },
    {
        label: 'FBA仓库信息',
        value: 3,
        path: '/Tool/Ware.html'
    },
    {
        label: '附加费查询',
        value: 4,
        path: '/Tool/AddFee.html'
    }
]